
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout } from '../../actions/AppActions';
import { connect } from 'react-redux';

// users
import moment from 'moment';
import { API_URL, STORAGE_URL, URL } from '../../variables';
import { maskNumber } from '../../Auxiliar/Masks';

import ReactPaginate from 'react-paginate';
import { MdReply } from 'react-icons/md';
import { Form, FormControl as Input, InputGroup, Button, Select, Spinner } from "react-bootstrap";
import ImageLoaderInput from '../ReusableComponents/ImageLoaderInput';
import Attention from '../ReusableComponents/Attention';
import { RiArrowGoBackLine } from 'react-icons/ri';
import DefaultButton from '../ReusableComponents/DefaultButton';
import JoditEditor from 'jodit-react';


import './label.css';
import { DatePicker, TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import 'moment/locale/pt-br';
import { alpha } from '@material-ui/core/styles'
import { FormControlLabel, Radio, TextField } from '@material-ui/core';
import { RadioGroup } from '@mui/material';

class EditarProcesso extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/',
            loading_screen: true,
            roles: [],
            name: '',
            regras: '',
            descricao: '',

            fim: moment().add(1, 'day'),
            hora_inicio: moment(),
            hora_fim: moment().add(1, 'hour'),

            loading_save: false,
            msgErro: '',
            loading_image: false,
            inicio: moment(),
            faculdade: { name: '', id: '' },
            fiscalizacoes: [
                { id: 'all', 'name': 'Salvar Vídeo e Imagem - Com streaming em tempo real' },
                { id: 'image', 'name': 'Salvar Apenas Imagem - Com streaming em tempo real' },
                { id: 'none', 'name': 'Salvar apenas logs - Sem streaming' },
            ],
            fiscalizacoes_simulado: [
                { id: 'all', 'name': 'Testar compartilhamento de câmera e áudio' },
                { id: 'none', 'name': 'Não compartilhar câmera e áudio' },
            ],
            fiscalizacao: 'all',

            chat_types: [
                { id: 'all', 'name': 'Ambos enviam mensagem' },
                { id: 'fiscal_to_student', 'name': 'Apenas o fiscal envia mensagem' },
                { id: 'none', 'name': 'Ninguem envia mensagem' },
            ],
            chat_type: 'all',

            times_save: [
                { id: '30000', 'name': '30 segundos' },
                { id: '15000', 'name': '15 segundos' },
            ],
            time_save: '30000',
            tempo_prova: '',
            type_time: 'all',
            online: true,
            full_screen:true,
            screen_extended_retrict:true,
            screen_sharing:false,
            mobile:false,
            exam_per_time:false,
            restrict_access:false,
            face_validation: false,
            is_interview:false,
            inicio_access: moment().subtract('hour',24),
            fim_access: moment(),
            hora_inicio_access: moment().subtract('hour',24),
            hora_fim_access: moment(),
            simulado: false,
            show_rascunho: true,
            need_pause:false
        };

        this.config = {
            height: 300,
            // askBeforePasteHTML:false,
            // askBeforePasteFromWord:false,
            // defaultActionOnPaste:'insert_only_text',
            // defaultActionOnPasteFromWord:'insert_only_text',
            // pasteHTMLActionList:[
            //     { value: 'insert_only_text', text: 'Insert only Text' }
            // ],
            // pasteFromWordActionList:[
            //     { value: 'insert_only_text', text: 'Insert only Text' }
            // ],
            readonly: this.state.loading_image,
            language: 'pt_br',
            toolbarButtonSize: 'large',
            "buttons": "source,bold,italic,underline,strikethrough,eraser,superscript,subscript,ul,ol,indent,outdent,left,center,right,justify,font,fontsize,paragraph,classSpan,image,brush,,undo,redo,hr,link,symbol,table",
            uploader: {
                url: API_URL + 'faculdade/upload_image',  //your upload api url
                insertImageAsBase64URI: false, //not inster base64
                imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
                headers: { "accept": `application/json`, 'Authorization': 'Bearer ' + localStorage.getItem('token') },
                filesVariableName: function (t) {
                    return 'imagem';
                }, //"files",
                withCredentials: false,
                pathVariableName: 'path',
                //   format: 'json',
                method: 'POST',
                prepareData: (formdata) => {
                    console.log(formdata)
                    this.setState({ loading_image: true });
                    return formdata;
                },
                isSuccess: (e) => {
                    console.log(e);

                    return 'Upload realizado com sucesso';
                },
                getMessage: (e) => {
                    console.log(e);

                    return e;
                },
                process: (resp) => { //success callback transfrom data to defaultHandlerSuccess use.it's up to you.
                    let files = [];
                    console.log(resp);
                    files.unshift(STORAGE_URL + resp.imagem);
                    this.setState({ loading_image: false });

                    return {
                        files: files,

                    };
                },
                error: (elem, e) => {
                    // elem.j.e.fire('errorMessage', e.message, 'error', 4000);

                    console.log(e);
                    console.log(elem);
                    console.log('Bearer ' + localStorage.getItem('token'));
                },
                defaultHandlerSuccess: function (resp, elem) { // `this` is the editor.
                    if (resp.files && resp.files.length) {
                        resp.files.forEach((filename, index) => { //edetor insertimg function
                            this.selection.insertImage(filename);
                        });
                    }

                    // debugger;

                },
                defaultHandlerError: (elem, e) => {
                    // elem.j.e.fire('errorMessage', e.message);
                    console.log(e);
                    console.log(elem);
                    this.setState({ loading_image: false });

                },
                //   contentType: function (e) {
                //     return (

                //       'application/x-www-form-urlencoded; charset=UTF-8'
                //     );
                //   },
            },
        }
    }

    get_processo(id) {

        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/faculdade/get_processo/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    this.setState({
                        loading_screen: false, faculdade: resp.processo.faculdade,
                        name: resp.processo.name,
                        inicio: moment(resp.processo.inicio),
                        fim: moment(resp.processo.fim),
                        hora_inicio: moment(resp.processo.inicio),
                        hora_fim: moment(resp.processo.fim),
                        regras: resp.processo.regras,
                        descricao: resp.processo.descricao,
                        online: resp.processo.online,
                        need_pause: resp.processo.need_pause,

                        restrict_access:resp.processo.restrict_access,
                        inicio_access:resp.processo.time_init_restrict==null?this.state.inicio_access:moment(resp.processo.time_init_restrict),
                        hora_inicio_access:resp.processo.time_init_restrict==null?this.state.hora_inicio_access:moment(resp.processo.time_init_restrict),

                        fim_access:resp.processo.time_end_restrict==null?this.state.fim_access:moment(resp.processo.time_end_restrict),
                        hora_fim_access:resp.processo.time_end_restrict==null?this.state.hora_fim_access:moment(resp.processo.time_end_restrict),


                        simulado: resp.processo.simulado,
                        exam_per_time: resp.processo.exam_per_time,
                        mobile: resp.processo.mobile,

                        show_rascunho: resp.processo.show_rascunho,
                        chat_type: resp.processo.chat_type,
                        time_save: resp.processo.save_timer,
                        fiscalizacao: resp.processo.type_fiscalization,
                        type_time: resp.processo.type_time,
                        screen_extended_retrict: resp.processo.screen_extended_retrict,
                        screen_sharing: resp.processo.screen_sharing,
                        face_validation: resp.processo.face_validation,
                        is_interview: resp.processo.is_interview,
                        full_screen: resp.processo.full_screen,

                        tempo_prova: resp.processo.tempo_prova == null ? '' : (resp.processo.tempo_prova + ''),
                    });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }



    salvar(e) {
        e.preventDefault();
        if(this.state.restrict_access==true && this.state.inicio_access.format('YYYY-MM-DD')+' '+this.state.hora_inicio_access.format('HH:mm')>this.state.inicio.format('YYYY-MM-DD')+' '+this.state.hora_inicio.format('HH:mm')){
            this.setState({  msgErro: 'A Data/hora de início deve ser maior que a Data/hora de início do intervalo de restrição de acesso' });
            window.scrollTo(500, 0);
            
            return;
        }
        if(this.state.restrict_access==true && this.state.fim_access.format('YYYY-MM-DD')+' '+this.state.hora_fim_access.format('HH:mm')>=this.state.fim.format('YYYY-MM-DD')+' '+this.state.hora_fim.format('HH:mm')){
            this.setState({  msgErro: 'A Data/hora de encerramento deve ser maior que a Data/hora de encerramento do intervalo de restrição de acesso' });
            window.scrollTo(500, 0);
            
            return;
        }

        this.setState({ loading_save: true, msgErro: '' });
        

        let data={
            name: this.state.name,
            descricao: this.state.descricao,
            regras: this.state.regras,
            hora_inicio: this.state.hora_inicio.format('HH:mm'),
            inicio: this.state.inicio.format('DD/MM/YYYY'),
            fim: this.state.fim.format('DD/MM/YYYY'),
            hora_fim: this.state.hora_fim.format('HH:mm'),

            hora_inicio_access: this.state.hora_inicio_access.format('HH:mm'),
            inicio_access: this.state.inicio_access.format('DD/MM/YYYY'),
            fim_access: this.state.fim_access.format('DD/MM/YYYY'),
            hora_fim_access: this.state.hora_fim_access.format('HH:mm'),

            processo_id: this.props.match.params.id,
            type_time: this.state.type_time,
            tempo_prova: this.state.tempo_prova,
            online: this.state.online,
            full_screen:this.state.full_screen,
            screen_extended_retrict:this.state.screen_sharing==true?true: this.state.screen_extended_retrict,
            screen_sharing:this.state.screen_sharing,
            face_validation: this.state.face_validation,

            hora_inicio_access: this.state.hora_inicio_access.format('HH:mm'),
            inicio_access: this.state.inicio_access.format('DD/MM/YYYY'),
            fim_access: this.state.fim_access.format('DD/MM/YYYY'),
            hora_fim_access: this.state.hora_fim_access.format('HH:mm'),

        restrict_access: this.state.restrict_access,
        simulado: this.state.simulado,
        show_rascunho: this.state.show_rascunho,
        mobile: this.state.mobile,
        need_pause: this.state.need_pause,
            // exam_per_time: this.state.exam_per_time,
        }




        fetch(`${URL}api/faculdade/update_processo`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
            },
            body: JSON.stringify(data)
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    window.scrollTo(500, 0);

                    this.setState({ loading_save: false, msgErro: erro });
                }
                else {

                    this.setState({ loading_save: false, redirect: true });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }


    componentDidMount() {
        this.get_processo(this.props.match.params.id);
    }



    componentWillUnmount(){
        Array.from(document.getElementsByClassName('jodit-wysiwyg')).forEach((el) => {
            // Do stuff here
            el.blur()
        })
    }

    change_tipo(event) {
        this.setState({ tipo: event.target.value });
    }


    render() {
        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={`/instituicao/show/${this.state.faculdade.id}`} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">


                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Editar {(this.props.user.empresa==null || this.props.user.empresa.change_text==false)?'Processo':'Vaga/Cargo'}</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Edite um processo seletivo {(this.props.user.empresa==null || this.props.user.empresa.change_text==false)?'da instituição':'do processo'} {this.state.faculdade.name}</p>
                            <br />

                            {/* <hr /> */}
                            {this.state.loading_screen == false && <div>
                                {this.state.msgErro != '' && <div style={{ display: 'flex', marginBottom: 15, width: '100%' }}>
                                    <div className="alert alert-danger alert-dismissible fade show mb-0 w-100" role="alert">
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({ msgErro: '' })}></button>


                                        <p style={{ marginBottom: 0 }}>{this.state.msgErro}</p>

                                    </div>
                                    <br />

                                </div>}
                                <Form onSubmit={(e) => this.salvar(e)} style={{ position: "relative" }}>
                                    <InputGroup>

                                        <div className="flex fdcolumn flex1 margin-bottom">
                                            <Form.Label>Nome <Attention /></Form.Label>
                                            <Input
                                                placeholder={"Nome "+((this.props.user.empresa==null || this.props.user.empresa.change_text==false)?'do processo':'da vaga/cargo')}
                                                value={this.state.name}
                                                onChange={(e) => this.setState({ name: e.target.value })}
                                                required
                                            // title="Coloque o nome completo do usuário"
                                            />
                                        </div>
                                    </InputGroup>
                                    <InputGroup>

                                        <div className="flex fdcolumn flex1 margin-bottom">
                                            <Form.Label>Descrição <Attention /></Form.Label>
                                            <Input
                                                placeholder={"Descrição "+((this.props.user.empresa==null || this.props.user.empresa.change_text==false)?'do processo':'da vaga/cargo')}
                                                value={this.state.descricao}
                                                onChange={(e) => this.setState({ descricao: e.target.value })}
                                                required
                                                as="textarea"
                                            />
                                        </div>
                                    </InputGroup>
                                    <InputGroup>

                                        <div className="flex fdcolumn flex1 margin-bottom">
                                        <Form.Label >Regras {(this.props.user.empresa==null || this.props.user.empresa.change_text==false)?'do processo':'da vaga/cargo'} <Attention /> {this.state.loading_image == true && <div className="spinner-border text-primary spinner-border-sm" role="status"  >
                                            </div>}</Form.Label>
                                            <JoditEditor
                                                ref={this.editor}
                                                value={this.state.regras}
                                                config={this.config}
                                                height={400}
                                                tabIndex={1} // tabIndex of textarea
                                                onBlur={newContent => this.setState({ regras: newContent })} // preferred to use only this option to update the content for performance reasons
                                            // onChange={newContent => {this.setState({enunciado:newContent}) }}
                                            />
                                        </div>
                                    </InputGroup>

                                    {this.props.user?.empresa?.online_proccess==true && <InputGroup>
                                        <div className="flex fdcolumn flex1 margin-bottom">
                                            <Form.Label>A avaliação é online? <Attention /></Form.Label>
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                name="radio-buttons-group"
                                                value={this.state.online}
                                                onChange={(e) => {
                                                    this.setState({ online: e.target.value });
                                                }}>

                                                <FormControlLabel onClick={(e) => {
                                                    this.setState({ online: true });
                                                }} control={<Radio checked={this.state.online == true} value={true} />} style={{ color: 'black' }} label={'Sim'} />
                                                <FormControlLabel onClick={(e) => {
                                                    console.log(e.target.value);
                                                    this.setState({ online: false });

                                                }} control={<Radio checked={this.state.online == false} value={false} />} style={{ color: 'black' }} label={'Não'} />
                                            </RadioGroup>
                                        </div>
                                    </InputGroup>}

                                    {this.props.user?.empresa?.online_proccess==true && this.state.online == true && <InputGroup>
                                        <div className="flex fdcolumn flex1 margin-bottom">
                                            <Form.Label>É um avaliação oral? <Attention /></Form.Label>
                                            <small>Se habilitado, esta vaga/cargo será uma entrevista (avaliação oral) com os candidatos vinculados a ele, onde será possível cadastrar os critérios de avaliação e quem serão os avaliadores. Cada avaliador poderá entrar em chamadas individuais com cada candidato.</small>
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                name="radio-buttons-group"
                                                value={this.state.is_interview}
                                                disabled
                                                onChange={(e) => {
                                                }}>

                                                <FormControlLabel disabled onClick={(e) => {
                                                }} control={<Radio checked={this.state.is_interview == true} value={true} />} style={{ color: 'black' }} label={'Sim'} />
                                                <FormControlLabel onClick={(e) => {
                                                    console.log(e.target.value);

                                                }} disabled control={<Radio checked={this.state.is_interview == false} value={false} />} style={{ color: 'black' }} label={'Não'} />
                                            </RadioGroup>
                                        </div>
                                    </InputGroup>}


                                    <MuiPickersUtilsProvider utils={MomentUtils}>

                                        <InputGroup>

                                            <div className="flex fdcolumn flex1 margin-bottom me-3">
                                                <Form.Label>Data de início <Attention /></Form.Label>
                                                <DatePicker
                                                    autoOk
                                                    margin="normal"
                                                    style={{ width: '100%' }}
                                                    invalidDateMessage="Data inválida"
                                                    ampm={false}
                                                    minDate={moment()}
                                                    id="time-picker"
                                                    cancelLabel="Cancelar"
                                                    clearLabel="Limpar"
                                                    format="DD/MM/YYYY"
                                                    value={this.state.inicio}
                                                    onChange={(date) => { this.setState({ inicio: date }) }}

                                                />
                                            </div>
                                            <div className="flex fdcolumn flex1 margin-bottom">
                                                <Form.Label>Horário de início <Attention /></Form.Label>
                                                <TimePicker
                                                    autoOk
                                                    margin="normal"
                                                    style={{ width: '100%' }}
                                                    invalidDateMessage="Horário inválido"
                                                    ampm={false}
                                                    id="time-picker"
                                                    cancelLabel="Cancelar"
                                                    clearLabel="Limpar"
                                                    label=""
                                                    value={this.state.hora_inicio}
                                                    onChange={(date) => { this.setState({ hora_inicio: date }) }}

                                                />
                                            </div>
                                        </InputGroup>

                                        <InputGroup>

                                            <div className="flex fdcolumn flex1 margin-bottom me-3">
                                                <Form.Label>Data de encerramento <Attention /></Form.Label>
                                                <DatePicker
                                                    autoOk
                                                    margin="normal"
                                                    style={{ width: '100%' }}
                                                    invalidDateMessage="Data inválida"
                                                    ampm={false}
                                                    minDate={moment()}
                                                    id="time-picker"
                                                    cancelLabel="Cancelar"
                                                    clearLabel="Limpar"
                                                    format="DD/MM/YYYY"
                                                    value={this.state.fim}
                                                    onChange={(date) => { this.setState({ fim: date }) }}

                                                />
                                            </div>
                                            <div className="flex fdcolumn flex1 margin-bottom">
                                                <Form.Label>Horário de encerramento <Attention /></Form.Label>
                                                <TimePicker
                                                    autoOk
                                                    margin="normal"
                                                    style={{ width: '100%' }}
                                                    invalidDateMessage="Horário inválido"
                                                    ampm={false}
                                                    id="time-picker"
                                                    cancelLabel="Cancelar"
                                                    clearLabel="Limpar"
                                                    label=""
                                                    value={this.state.hora_fim}
                                                    onChange={(date) => { this.setState({ hora_fim: date }) }}

                                                />
                                            </div>
                                        </InputGroup>


                                    </MuiPickersUtilsProvider>
                                    {this.state.online == true && <>
                                        <InputGroup>
                                            <div className="flex fdcolumn flex1 margin-bottom">
                                                <Form.Label>É um simulado para teste de compatibilidade dos candidatos? <Attention /></Form.Label>
                                                <small>Se habilitado, essa vaga/cargo será um teste para os candidatos conseguirem acessar o sistema e testar funções como compartilhamento de câmera/áudio/tela. Será gerado também uma prova teste dentro da vaga/cargo.</small>
                                                <RadioGroup
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    name="radio-buttons-group"
                                                    value={this.state.simulado}
                                                    disabled
                                                    onChange={(e) => {
                                                        let data = {}
                                                        if (e.target.value == true) {
                                                            data.type_time = 'restrict';
                                                            data.tempo_prova = 4;
                                                            data.fiscalizacao = 'all';
                                                            data.chat_type = 'none';
                                                            data.exam_per_time=false;

                                                        }
                                                        else {
                                                            data.chat_type = 'all';
                                                            data.type_time = 'all';
                                                            data.tempo_prova = '';
                                                        }
                                                        return;
                                                        this.setState({ simulado: e.target.value, ...data });
                                                    }}>

                                                    <FormControlLabel onClick={(e) => {
                                                        let data = {}
                                                        data.type_time = 'restrict';
                                                        data.tempo_prova = 4;
                                                        data.fiscalizacao = 'all';
                                                        data.chat_type = 'none';
                                                        data.exam_per_time=false;
                                                        return;

                                                        this.setState({ simulado: true, ...data });
                                                    }} disabled control={<Radio checked={this.state.simulado == true} value={true} />} style={{ color: 'black' }} label={'Sim'} />
                                                    <FormControlLabel onClick={(e) => {
                                                        let data = {}
                                                        data.chat_type = 'all';
                                                        data.type_time = 'all';
                                                        data.tempo_prova = '';
                                                        return;

                                                        this.setState({ simulado: false, ...data });

                                                    }} disabled control={<Radio checked={this.state.simulado == false} value={false} />} style={{ color: 'black' }} label={'Não'} />

                                                </RadioGroup>



                                            </div>
                                        </InputGroup>
                                        <InputGroup>
                                            <div className="flex fdcolumn flex1 margin-bottom">
                                                <Form.Label>Cada avaliação terá um tempo máximo para fazer? <Attention /></Form.Label>
                                                <small>Se habilitado, na hora de criar a prova será obrigatório definir o tempo, em minutos, que cada questão terá para ser respondida. Caso não queira que tenha tempo por questão, desabilite-a.</small>
                                                <RadioGroup
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    name="radio-buttons-group"
                                                    value={this.state.exam_per_time}
                                                    onChange={(e) => {
                                                        if(this.state.simulado==true){
                                                            return;
                                                        }
                                                    }}>

                                                    <FormControlLabel onClick={(e) => {
                                                        if(this.state.simulado==true){
                                                            return;
                                                        }
                                                    }} disabled control={<Radio checked={this.state.exam_per_time == true} value={true} />} style={{ color: 'black' }} label={'Sim'} />
                                                    <FormControlLabel onClick={(e) => {
                                                        if(this.state.simulado==true){
                                                            return;
                                                        }

                                                    }} disabled control={<Radio checked={this.state.exam_per_time == false} value={false} />} style={{ color: 'black' }} label={'Não'} />

                                                </RadioGroup>



                                            </div>
                                        </InputGroup>
                                        <InputGroup>
                                            <div className="flex fdcolumn flex1 margin-bottom">
                                                <Form.Label>O candidato poderá solicitar pausa durante a prova? <Attention /></Form.Label>
                                                <small>Se habilitado, é exibilido um botão de "Pedir Pausa" do lado do candidato, onde ele poderá solicitar pausas durante a prova com uma justificativa, por exemplo: preciso ir ao banheiro, preciso amamentar, etc. O fiscal vinculado a sala daquele candidato deverá aceitar ou negar a pausa solicitada.</small>

                                                <RadioGroup
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    name="radio-buttons-group"
                                                    value={this.state.need_pause}
                                                    onChange={(e) => {
                                                        
                                                        this.setState({ need_pause: e.target.value });
                                                    }}>

                                                    <FormControlLabel onClick={(e) => {
                                                        
                                                        this.setState({ need_pause: true });
                                                    }}  control={<Radio checked={this.state.need_pause == true} value={true} />} style={{ color: 'black' }} label={'Sim'} />
                                                    <FormControlLabel onClick={(e) => {
                                                       
                                                        this.setState({ need_pause: false });

                                                    }} control={<Radio checked={this.state.need_pause == false} value={false} />} style={{ color: 'black' }} label={'Não'} />

                                                </RadioGroup>
                                            </div>
                                        </InputGroup>
                                        <InputGroup>
                                            <div className="flex fdcolumn flex1 margin-bottom">
                                                <Form.Label>O candidato poderá acessar por dispositivos móveis? <Attention /></Form.Label>
                                                <small>Se habilitado, a avaliação também poderá ser realizada por dispositivos móveis, porém algumas funções não poderão ser habilitadas, sendo estas: Gravação de tela e Validação facial.</small>

                                                <RadioGroup
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    name="radio-buttons-group"
                                                    value={this.state.mobile}
                                                    onChange={(e) => {
                                                        
                                                        // this.setState({ mobile: e.target.value });
                                                    }}>

                                                    <FormControlLabel onClick={(e) => {
                                                        let data ={};
                                                        data.screen_sharing=false;
                                                        data.face_validation=false;
                                                        
                                                        this.setState({ mobile: true,...data });
                                                    }}  control={<Radio checked={this.state.mobile == true} value={true} />} style={{ color: 'black' }} label={'Sim'} />
                                                    <FormControlLabel onClick={(e) => {
                                                        this.setState({ mobile: false });

                                                    }}  control={<Radio checked={this.state.mobile == false} value={false} />} style={{ color: 'black' }} label={'Não'} />

                                                </RadioGroup>



                                            </div>
                                        </InputGroup>
                                        <InputGroup>
                                            <div className="flex fdcolumn flex1 margin-bottom">
                                                <Form.Label>Disponibilizar campo para rascunho em cada questão? <Attention /></Form.Label>
                                                <small>Se habilitado, em cada questão terá um botão de rascunho para o candidato, onde será aberto um caixa de texto para o candidato excrever. O rascunho não é salvo pelo sistema.</small>

                                                <RadioGroup
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    name="radio-buttons-group"
                                                    value={this.state.show_rascunho}
                                                    onChange={(e) => {
                                                        this.setState({ show_rascunho: e.target.value });
                                                    }}>

                                                    <FormControlLabel onClick={(e) => {
                                                        this.setState({ show_rascunho: true });
                                                    }} control={<Radio checked={this.state.show_rascunho == true} value={true} />} style={{ color: 'black' }} label={'Sim'} />
                                                    <FormControlLabel onClick={(e) => {
                                                        this.setState({ show_rascunho: false });

                                                    }} control={<Radio checked={this.state.show_rascunho == false} value={false} />} style={{ color: 'black' }} label={'Não'} />

                                                </RadioGroup>



                                            </div>
                                        </InputGroup>

                                        <InputGroup>
                                        <div className="flex fdcolumn flex1 margin-bottom">
                                                <Form.Label>Obrigar o uso de tela cheia para ver a avaliação? <Attention /></Form.Label>
                                                <small>Se habilitado, o candidato só conseguirá fazer a avaliação com o sistema em tela cheia. Caso ele saia da tela cheia, o sistema registro como saída de tela.</small>

                                                <RadioGroup
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    name="radio-buttons-group"
                                                    value={this.state.full_screen}
                                                    onChange={(e) => {
                                                        this.setState({ full_screen: e.target.value });
                                                    }}>

                                                    <FormControlLabel onClick={(e) => {
                                                        this.setState({ full_screen: true });
                                                    }} control={<Radio checked={this.state.full_screen == true} value={true} />} style={{ color: 'black' }} label={'Sim'} />
                                                    <FormControlLabel onClick={(e) => {
                                                        this.setState({ full_screen: false });

                                                    }} control={<Radio checked={this.state.full_screen == false} value={false} />} style={{ color: 'black' }} label={'Não'} />

                                                </RadioGroup>

                                              

                                            </div>
                                        </InputGroup>
                                        <InputGroup>

                                            <div className="flex fdcolumn flex1 margin-bottom">
                                                <Form.Label>Tempo de prova <Attention /></Form.Label>

                                                <RadioGroup
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    name="radio-buttons-group"
                                                    value={this.state.type_time}
                                                    disabled={this.state.simulado}

                                                    onChange={(e) => {
                                                        if(this.state.simulado){
                                                            return;
                                                        }
                                                        this.setState({ type_time: e.target.value });
                                                    }}>

                                                    <FormControlLabel 
                                                    disabled={this.state.simulado}
                                                    
                                                    onClick={(e) => {
                                                        if(this.state.simulado){
                                                            return;
                                                        }
                                                        this.setState({ type_time: 'all' });
                                                    }} control={<Radio checked={this.state.type_time == 'all'} value={'all'} />} style={{ color: 'black' }} label={'Candidato pode usar o tempo total de prova'} />
                                                    <FormControlLabel 
                                                    disabled={this.state.simulado}
                                                    
                                                    onClick={(e) => {
                                                        if(this.state.simulado){
                                                            return;
                                                        }
                                                        this.setState({ type_time: 'restrict' });

                                                    }} control={<Radio checked={this.state.type_time == 'restrict'} value={'restrict'} />} style={{ color: 'black' }} label={'Candidato tem um tempo de prova restrito a partir do acesso'} />

                                                </RadioGroup>

                                                {this.state.type_time == 'restrict' && <>
                                                    <Form.Label>Tempo de prova em minutos <Attention /></Form.Label>
                                                    <Input
                                                    disabled={this.state.simulado}

                                                        placeholder="Tempo de prova em minutos que o candidato terá após o primeiro acesso"
                                                        value={this.state.tempo_prova}
                                                        onChange={(e) => this.setState({ tempo_prova: e.target.value })}
                                                        required
                                                        type='number'
                                                    // title="Coloque o nome completo do usuário"
                                                    />
                                                </>}


                                            </div>


                                        </InputGroup>
                                        {this.state.simulado==false && <InputGroup>
                                            <div className="flex fdcolumn flex1 margin-bottom">
                                                <Form.Label>Tipo de Fiscalização <Attention /></Form.Label>
                                                <select disabled className="form-select " onChange={(e) => {
                                                    let chat_type = this.state.chat_type;
                                                    let time_save = this.state.time_save;

                                                    if (e.target.value == 'all') {
                                                        chat_type = 'all';
                                                        time_save = '30000';
                                                    }
                                                    else if (e.target.value == 'image') {
                                                        // chat_type='all';
                                                        // time_save='30000';
                                                    }
                                                    else if (e.target.value == 'none') {
                                                        chat_type = 'all';
                                                        time_save = '30000';
                                                    }
                                                    this.setState({ fiscalizacao: e.target.value, chat_type, time_save,face_validation:false, })
                                                }} value={this.state.fiscalizacao}>
                                                    {(this.state.simulado==false && this.state.fiscalizacoes && this.state.fiscalizacoes.length != 0) && this.state.fiscalizacoes.map((value, index) => (
                                                        <option
                                                            key={index}
                                                            value={value["id"]}
                                                        >{value["name"]}</option>
                                                    ))}
                                                    {(this.state.simulado==true && this.state.fiscalizacoes_simulado && this.state.fiscalizacoes_simulado.length != 0) && this.state.fiscalizacoes_simulado.map((value, index) => (
                                                        <option
                                                            key={index}
                                                            value={value["id"]}
                                                        >{value["name"]}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </InputGroup>}
                                        {this.state.fiscalizacao == 'image' && <InputGroup>
                                            <div className="flex fdcolumn flex1 margin-bottom">
                                                <Form.Label>Tipo de Chat <Attention /></Form.Label>
                                                <select disabled className="form-select " onChange={(e) => {

                                                    this.setState({ chat_type: e.target.value })
                                                }} value={this.state.chat_type}>
                                                    {(this.state.chat_types && this.state.chat_types.length != 0) && this.state.chat_types.map((value, index) => (
                                                        <option
                                                            key={index}
                                                            value={value["id"]}
                                                        >{value["name"]}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </InputGroup>}

                                        {/* {this.state.fiscalizacao == 'image' && <InputGroup>
                                            <div className="flex fdcolumn flex1 margin-bottom">
                                                <Form.Label>Gerar imagem do candidato a cada <Attention /></Form.Label>
                                                <select disabled className="form-select " onChange={(e) => {
                                                    this.setState({ time_save: e.target.value })
                                                }} value={this.state.time_save}>
                                                    {(this.state.times_save && this.state.times_save.length != 0) && this.state.times_save.map((value, index) => (
                                                        <option
                                                            key={index}
                                                            value={value["id"]}
                                                        >{value["name"]}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </InputGroup>} */}


                                        <InputGroup>
                                        <div className="flex fdcolumn flex1 margin-bottom">
                                                <Form.Label>Bloquear uso de duas ou mais telas extendidas? <Attention /></Form.Label>

                                                <RadioGroup
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    name="radio-buttons-group"
                                                    value={this.state.screen_extended_retrict}
                                                    onChange={(e) => {
                                                        // this.setState({ screen_extended_retrict: e.target.value });
                                                    }}>
<FormControlLabel onClick={(e) => {
                                                        if(this.state.screen_sharing==true){
                                                            return;
                                                        }
                                                        this.setState({ screen_extended_retrict: true });
                                                    }} disabled={this.state.screen_sharing == true} control={<Radio checked={this.state.screen_extended_retrict == true} value={true} />} style={{ color: 'black' }} label={'Sim'} />
                                                    <FormControlLabel onClick={(e) => {
                                                         if(this.state.screen_sharing==true){
                                                            return;
                                                        }
                                                        this.setState({ screen_extended_retrict: false });

                                                    }} disabled={this.state.screen_sharing == true} control={<Radio checked={this.state.screen_extended_retrict == false} value={false} />} style={{ color: 'black' }} label={'Não'} />

                                                </RadioGroup>

                                              

                                            </div>
                                        </InputGroup>

                                        {this.state.fiscalizacao == 'all' && this.state.mobile==false && <InputGroup>
                                            <div className="flex fdcolumn flex1 margin-bottom">
                                            <Form.Label>{this.state.simulado == true ? 'Obrigar compartilhamento de tela para o teste? (Simula a gravação de tela para caso for usar em uma prova real)' : 'Gravar a tela do candidato? (Exige compartilhamento de tela'} <Attention /></Form.Label>
                                            <RadioGroup
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    name="radio-buttons-group"
                                                    value={this.state.screen_sharing}
                                                    onChange={(e) => {
                                                        // this.setState({ screen_sharing: e.target.value });
                                                    }}>

                                                    <FormControlLabel onClick={(e) => {
                                                        if(this.state.mobile==true){
                                                            return;
                                                        }
                                                        this.setState({ screen_sharing: true, screen_extended_retrict: true });
                                                    }} disabled={this.state.mobile} control={<Radio checked={this.state.screen_sharing == true} value={true} />} style={{ color: 'black' }} label={'Sim'} />
                                                    <FormControlLabel onClick={(e) => {
                                                        if(this.state.mobile==true){
                                                            return;
                                                        }
                                                        this.setState({ screen_sharing: false });

                                                    }} disabled={this.state.mobile} control={<Radio checked={this.state.screen_sharing == false} value={false} />} style={{ color: 'black' }} label={'Não'} />

                                                </RadioGroup>
                                            </div>
                                            <div className="flex fdcolumn flex1 margin-bottom">
                                                <Form.Label>{'Usar inteligência artificial para ter validação facial na entrada?'} <Attention /></Form.Label>
                                                <small>Se habilitada o candidato só conseguirá acessar a prova caso tenha sido identificado um rosto (apenas um) na imagem de sua câmera, e será necessário ele fazer movimentos para a esquerda e direita com a cabeça para validar sua entrada</small>
                                                <RadioGroup
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    name="radio-buttons-group"
                                                    value={this.state.face_validation}
                                                    onChange={(e) => {
                                                        // this.setState({ screen_sharing: e.target.value });
                                                    }}>

                                                    <FormControlLabel onClick={(e) => {
                                                        if(this.state.mobile==true){
                                                            return;
                                                        }
                                                        this.setState({ face_validation: true });
                                                    }} disabled={this.state.mobile} control={<Radio checked={this.state.face_validation == true} value={true} />} style={{ color: 'black' }} label={'Sim'} />
                                                    <FormControlLabel onClick={(e) => {
                                                        if(this.state.mobile==true){
                                                            return;
                                                        }
                                                        this.setState({ face_validation: false });

                                                    }} disabled={this.state.mobile} control={<Radio checked={this.state.face_validation == false} value={false} />} style={{ color: 'black' }} label={'Não'} />

                                                </RadioGroup>
                                            </div>
                                        </InputGroup>}

                                        <InputGroup>
                                            <div className="flex fdcolumn flex1 margin-bottom">
                                                <Form.Label>Restringir horário de acesso do candidato? <Attention /></Form.Label>
                                                <small>Candidatos que não tiverem acessado o sistema dentro de um determinado horário, não conseguiram acessar a prova</small>
                                                 <RadioGroup
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    name="radio-buttons-group"
                                                    value={this.state.restrict_access}
                                                    onChange={(e) => {
                                                        this.setState({ restrict_access: e.target.value });
                                                    }}>

                                                    <FormControlLabel onClick={(e) => {
                                                        this.setState({ restrict_access: true });
                                                    }} control={<Radio checked={this.state.restrict_access == true} value={true} />} style={{ color: 'black' }} label={'Sim'} />
                                                    <FormControlLabel onClick={(e) => {
                                                        this.setState({ restrict_access: false });

                                                    }} control={<Radio checked={this.state.restrict_access == false} value={false} />} style={{ color: 'black' }} label={'Não'} />

                                                </RadioGroup>
                                            </div>
                                        </InputGroup>

                                        {this.state.restrict_access==true && <MuiPickersUtilsProvider utils={MomentUtils}>

                                        <InputGroup>

                                            <div className="flex fdcolumn flex1 margin-bottom me-3">
                                                <Form.Label>Data inicial do intervalo <Attention /></Form.Label>
                                                <DatePicker
                                                    autoOk
                                                    margin="normal"
                                                    style={{ width: '100%' }}
                                                    invalidDateMessage="Data inválida"
                                                    ampm={false}
                                                    id="time-picker"
                                                    cancelLabel="Cancelar"
                                                    clearLabel="Limpar"
                                                    format="DD/MM/YYYY"
                                                    value={this.state.inicio_access}
                                                    onChange={(date) => { this.setState({ inicio_access: date }) }}

                                                />
                                            </div>
                                            <div className="flex fdcolumn flex1 margin-bottom">
                                                <Form.Label>Horário inicial do intervalo <Attention /></Form.Label>
                                                <TimePicker
                                                    autoOk
                                                    margin="normal"
                                                    style={{ width: '100%' }}
                                                    invalidDateMessage="Horário inválido"
                                                    ampm={false}
                                                    id="time-picker"
                                                    cancelLabel="Cancelar"
                                                    clearLabel="Limpar"
                                                    label=""
                                                    value={this.state.hora_inicio_access}
                                                    onChange={(date) => { this.setState({ hora_inicio_access: date }) }}

                                                />
                                            </div>
                                        </InputGroup>

                                        <InputGroup>

                                            <div className="flex fdcolumn flex1 margin-bottom me-3">
                                                <Form.Label>Data de encerramento do intervalo <Attention /></Form.Label>
                                                <DatePicker
                                                    autoOk
                                                    margin="normal"
                                                    style={{ width: '100%' }}
                                                    invalidDateMessage="Data inválida"
                                                    ampm={false}
                                                    id="time-picker"
                                                    cancelLabel="Cancelar"
                                                    clearLabel="Limpar"
                                                    format="DD/MM/YYYY"
                                                    value={this.state.fim_access}
                                                    onChange={(date) => { this.setState({ fim_access: date }) }}

                                                />
                                            </div>
                                            <div className="flex fdcolumn flex1 margin-bottom">
                                                <Form.Label>Horário de encerramento do intervalo <Attention /></Form.Label>
                                                <TimePicker
                                                    autoOk
                                                    margin="normal"
                                                    style={{ width: '100%' }}
                                                    invalidDateMessage="Horário inválido"
                                                    ampm={false}
                                                    id="time-picker"
                                                    cancelLabel="Cancelar"
                                                    clearLabel="Limpar"
                                                    label=""
                                                    value={this.state.hora_fim_access}
                                                    onChange={(date) => { this.setState({ hora_fim_access: date }) }}

                                                />
                                            </div>
                                        </InputGroup>



                                    </MuiPickersUtilsProvider>}

                                    </>}
                                    {this.state.loading_save == false && <div className="w100 inline-flex jcsb margin-top-1">
                                        {/* <Link to={`/accounts/list`}>
                                    <Button variant="secondary"><RiArrowGoBackLine/> Voltar</Button>
                                </Link> */}
                                        <DefaultButton
                                            bg="secondary"
                                            text="Voltar"
                                            icon={<RiArrowGoBackLine />}
                                            to={`/instituicao/show/${this.state.faculdade.id}`}
                                        />
                                        {/* <Button variant="success" type="submit" disabled={this.loading}>
                                    {this.state.loading ? <Spinner /> : ""}
                                    Registrar usuário
                                </Button> */}
                                        <DefaultButton
                                            type="submit"
                                            bg="confirm"
                                            text={"Salvar "+((this.props.user.empresa==null || this.props.user.empresa.change_text==false)?'processo':'vaga/cargo')}
                                            disabled={this.state.loading_image == true}
                                            loadingtext={"Salvando "+((this.props.user.empresa==null || this.props.user.empresa.change_text==false)?'processo':'vaga/cargo')}
                                            loading={this.state.loading_save}
                                        />
                                    </div>}

                                    {this.state.loading_save == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center margin-top-1" >
                                        <div className="spinner-border text-primary" role="status"  >
                                        </div>
                                    </div></div>}
                                </Form>

                            </div>}

                            {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                </div>
                            </div></div>}


                        </div>

                    </div>
                </div>
            </div >
        );
    }


}
const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
        user: state.AppReducer.user,
    }
);

export default connect(mapsStateToProps, { logout })(EditarProcesso);


